@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
    display: grid;
    place-content: center;
    position: relative;
    overflow: hidden;
    --position: 9.999%;
}

.slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}

.image-after {
    position: absolute;
    inset: 0;
    width: var(--position);
}

.slider {
    position: absolute;
    inset: 0;
    cursor: pointer;
    opacity: 0;
    /* for Firefox */
    width: 100%;
    height: 100%;
}

.slider:focus-visible~.slider-button {
    outline: 5px solid black;
    outline-offset: 3px;
}

.slider-line {
    position: absolute;
    inset: 0;
    width: .2rem;
    height: 100%;
    /* background-color: #fff; */
    /* z-index: 10; */
    left: var(--position);
    transform: translateX(-50%);
    pointer-events: none;
}

.slider-button {
    position: absolute;
    background-color: #fff;
    color: black;
    padding: .5rem;
    border-radius: 100vw;
    display: grid;
    place-items: center;
    top: 50%;
    left: var(--position);
    transform: translate(-50%, -50%);
    pointer-events: none;
    box-shadow: 1px 1px 1px hsl(0, 50%, 2%, .5);
}


/* Rotating text */
.rotating-text {
    font-weight: 400;
    font-size: 4.5rem;
    line-height: 1;
}

@media only screen and (min-width: 768px) {
    .rotating-text {
        font-weight: 400;
        font-size: 5.5rem;
        line-height: 1;
    }
}

.rotating-text h1 {
    display: inline-flex;
    margin: 0;
    vertical-align: top;
}

.rotating-text h1 .word {
    position: absolute;
    display: flex;
    opacity: 0;
}

.rotating-text h1 .word .letter {
    transform-origin: center center 25px;
}

.rotating-text h1 .word .letter.out {
    transform: rotateX(90deg);
    transition: 0.32s cubic-bezier(0.6, 0, 0.7, 0.2);
}

.rotating-text h1 .word .letter.in {
    transition: 0.38s ease;
}

.rotating-text h1 .word .letter.behind {
    transform: rotateX(-90deg);
}

.alizarin {
    color: #e74c3c;
}

.wisteria {
    color: #8e44ad;
}

.peter-river {
    color: #3498db;
}

/* Display model loader file */
.object {
    animation: MoveUpDown 2s linear infinite;
    position: absolute;
    left: 0;
    bottom: 0;

}

.scanner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgb(0, 255, 13);
    box-shadow: 0px 0px 8px 10px rgba(0, 255, 13, 0.45);
    animation: scan 4s linear infinite;
}

@keyframes scan {

    0%,
    100% {
        top: 0;
    }

    50% {
        top: 100%;
    }
}

.boxShadow {
    box-shadow: 3px 5px #aaa;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
}