.line {
    width: 100px;
    height: 1px;
    /* background: #fff; */
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.path {
    stroke: #CA3532;
    fill: transparent;
    stroke-width: 15;
    stroke-miterlimit: 18;
    stroke-dasharray: 500;
    stroke-dashoffset: -500;
    animation: animate 2s backwards infinite;
    /* Animation Shorthand */
}

.line::before {

    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: green;
    animation: animate2 2s linear infinite
}

@keyframes animate {
    0% {
        stroke-dashoffset: 500;
    }


}

@keyframes animate2 {
    0% {
        left: 0%;
    }

    50% {
        left: 100%;
    }

    0% {
        left: 0%;
    }
}